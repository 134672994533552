import http from "../http-common";

class AttendanceService {
  listDates() {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/dates", {params: {token: token}});
  }

  listMembers(calendarId) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/members/" + calendarId, {params: {token: token}});
  }

  searchMembers(calendarId, q) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/member-search/" + calendarId, {params: {token: token, q: q}});
  }
  listSignups(calendarId) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/signups/" + calendarId, {params: {token: token}});
  }
  updateAttendance(signupId, attended) {
    const token = window.sessionStorage.getItem("token");
    return http.patch("/attendances/" + signupId, {attended: attended}, {params: {token: token}});
  }
  addSignup(calendarId, accountId, childId) {
    const token = window.sessionStorage.getItem("token");
    return http.post("/attendances", {calendarId: calendarId, accountId: accountId, childId: childId}, {params: {token: token}});
  }
}

export default new AttendanceService();
