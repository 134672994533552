import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "../common/with-router";
import AccountDataService from "../services/account.service";

class Signups extends Component {
  constructor(props) {
    super(props);
    this.onShowSignups = this.onShowSignups.bind(this);
    this.onShowAttendance = this.onShowAttendance.bind(this);
    this.onChangeSignup = this.onChangeSignup.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.saveSignups = this.saveSignups.bind(this);
    this.handleError = this.handleError.bind(this);
    this.state = {
      tab: "signups",
      account: null,
      locations: [],
      calendars: [],
      pastCalendars: [],
      attendanceReports: [],
      button: "Loading...",
      edited: false,
      backgroundCheckStatus: null
    };
    var token = window.sessionStorage.getItem("token");
    this.getSignups(token);
  }

  getSignups(token) {
    var data = {token: token};
    AccountDataService.getSignups(data)
      .then(response => {
        const data = response.data;
        const children = data.account.children;
        const calendars = data.calendars;
        const hasChildren = this.hasChildren(children);
        for (var index = 0; index < calendars.length; ++index) {
          const calendar = calendars[index];
          for (var index2 = 0; index2 < calendar.signups.length; ++index2) {
            calendar.signups[index2].signedup = true;
          }
          if (!hasChildren) {
            if (!this.doesChildExist(calendar.signups, null)) {
              calendar.signups.push({signedup: false, childId: null});
            }
          } else {
            const q = Array(children.length).fill(false);
            for (var signupIndex = 0; signupIndex < calendar.signups.length; ++signupIndex) {
              const signup = calendar.signups[signupIndex];
              for (var childIndex = 0; childIndex < children.length; ++childIndex) {
                if (children[childIndex].id === signup.childId) {
                  q[childIndex] = true;
                }
              }
            }
            for (childIndex = 0; childIndex < children.length; ++childIndex) {
              if (!q[childIndex] && !children[childIndex].deleted) {
                calendar.signups.push({childId: children[childIndex].id, signedup: false});
              }
            }
          }
        }
        const attendees = [];
        for (const pastCalendar of data.pastCalendars) {
          for (const pastCalendarSignup of pastCalendar.signups) {
            this.addAttendee(attendees, pastCalendarSignup.childId);
          }
        }
        const attendanceReports = [];
        for (const attendee of attendees) {
          var name = data.account.firstName + " " + data.account.lastName;
          if (attendee !== null) {
            const childName = this.getName(children, attendee);
            name = childName + " - " + name;
          }
          const attendances = [];
          var hours = 0;
          for (const pastCalendar of data.pastCalendars) {
            for (const pastCalendarSignup of pastCalendar.signups) {
              if (pastCalendarSignup.childId === attendee) {
                attendances.push({locationId: pastCalendar.locationId, startTime: pastCalendar.startTime, attended: pastCalendarSignup.attended, hours: pastCalendarSignup.hours});
                if (pastCalendarSignup.attended) {
                  hours += Number(pastCalendarSignup.hours);
                }
              }
            }
          }
          attendanceReports.push({name: name, hours: hours, attendances: attendances});
        }
        this.setState({account: data.account, locations: data.locations, calendars: calendars, pastCalendars: data.pastCalendars, attendanceReports: attendanceReports, button: null, edited: false});
      }).catch(this.handleError);
  }

  getName(children, childId) {
    for (const child of children) {
      if (child.id === childId) {
        return child.name;
      }
    }
    return "?";
  }

  addAttendee(attendees, childId) {
    for (const attendee of attendees) {
      if (attendee === childId) {
        return;
      }
    }
    attendees.push(childId);
  }

  hasChildren(children) {
    for (var index = 0; index < children.length; ++index) {
      if (!children[index].deleted) {
        return true;
      }
    }
    return false;
  }

  hasAnyChildSignup(calendars) {
    for (var index = 0; index < calendars.length; ++index) {
      const calendar = calendars[index];
      const signups = calendar.signups;
      for (var signupIndex = 0; signupIndex < signups.length; ++signupIndex) {
        const signup = signups[signupIndex];
        if (signup.childId !== null) {
          return true;
        }
      }
    }
    return false;
  }

  doesChildExist(signups, childId) {
    for (var index = 0; index < signups.length; ++index) {
      if (signups[index].childId === childId) {
        return true;
      }
    }
    return false;
  }

  isHomeSignup(account, locationId) {
    return account.locationId === locationId;
  }

  isSignedup(signups) {
    for (var index = 0; index < signups.length; ++index) {
      if (signups[index].signedup) {
        return true;
      }
    }
    return false;
  }

  getLocationName(locationId) {
    for (var index = 0; index < this.state.locations.length; ++index) {
      const location = this.state.locations[index];
      if (location.id === locationId) {
        return location.name;
      }
    }
    return "<Error>";
  }

  onShowSignups(e) {
    this.setState({tab: "signups"});
  }

  onShowAttendance(e) {
    this.setState({tab: "attendance"});
  }

  onChangeSignup(e) {
    const ids = e.target.id.substr(4).split("-");
    const calendarId = parseInt(ids[0]);
    const childId = ids[1] === "null" ? null : parseInt(ids[1]);
    var calendars = this.state.calendars;
    for (var index = 0; index < calendars.length; ++index) {
      if (calendars[index].id === calendarId) {
        for (var signupIndex = 0; signupIndex < calendars[index].signups.length; ++signupIndex) {
          if (calendars[index].signups[signupIndex].childId === childId) {
            calendars[index].signups[signupIndex].signedup = !calendars[index].signups[signupIndex].signedup;
          }
        }
      }
    }
    this.setState({calendars: calendars, edited: true});
  }

  onChangeLocation(e) {
    var account = this.state.account;
    account.locationId = e.target.value === "" ? null : parseInt(e.target.value);
    this.setState({ account: account });
  }

  saveSignups() {
    var token = window.sessionStorage.getItem("token");
    const data = {token: token, calendars: this.state.calendars};
    this.setState({button: "Saving..."});
    AccountDataService.saveSignups(data)
    .then(response => {
      this.getSignups(token);
    }).catch(this.handleError);
  }

  handleError(e) {
    if (e.response.status === 403) {
      if (e.response.data.code === 4011) {
        this.setState({backgroundCheckStatus: e.response.data.backgroundCheckStatus});
      } else {
        this.props.router.navigate("/login");
      }
    }
  }

  getChildName(childId) {
    if (childId == null) {
      return "Yes";
    }
    for (var childIndex = 0; childIndex < this.state.account.children.length; ++childIndex) {
      if (this.state.account.children[childIndex].id === childId) {
        return this.state.account.children[childIndex].name;
      }
    }
    return "<error>";
  }

  isChildDeleted(childId) {
    if (childId == null) {
      return false;
    }
    for (var childIndex = 0; childIndex < this.state.account.children.length; ++childIndex) {
      if (this.state.account.children[childIndex].id === childId) {
        return this.state.account.children[childIndex].deleted;
      }
    }
    return false;
  }

  areThereSignups(calendars, account) {
    for (var index = 0; index < calendars.length; ++index) {
      const calendar = calendars[index];
      if (this.isHomeSignup(account, calendar.locationId) || this.isSignedup(calendar.signups)) {
        return true;
      }
    }
    return false;
  }

  render() {
    return (
      <div className="fancy-box">
        <h1 className="mb-3">Sessions</h1>
        {this.state.backgroundCheckStatus !== null && (
          <div>
            <div>
              <p><strong>Intellichoice requires all tutors and volunteers who are 18 years or older to pass a background check (Valid for 3 years).</strong></p>
              {this.state.backgroundCheckStatus === 'rejected' && (
                <p>We ran the background check for you and based on information from our background check vendor, you did not pass the background check. We regret to let you know that we cannot approve of you being a tutor and/or volunteer for Intellichoice. Thank you for your interest.</p>
              )}
              {this.state.backgroundCheckStatus !== 'rejected' && (
                <div>
                  <p>Intellichoice is a volunteer-driven 501 (C ) (3) organization with no paid staff. All the monies donated go to facilitate tutoring programs and the scholarship fund.</p>
                  <p>The background check costs $9.99. If are able to, you may pay for the background check yourself or let Inteliichoice pay for it using one the of options below.</p>
                  <p>
                    <strong className="text-primary">
                      * NOTE: It takes up to 5 days to process the background check. <u>Please do not re-submit</u>. Please contact us for any questions at info@intellichoice.org
                    </strong>
                  </p>
                </div>
              )}
            </div>
            {this.state.backgroundCheckStatus !== 'rejected' && (
              <div>
                <a href="https://intellichoiceinc.quickapp.pro/apply/applicant/new/11329" className="btn btn-primary btn-large">
                  Proceed to<br/>
                  <span className="fs-5">
                    Background Check<br/>
                    (You agree to pay $9.99)
                  </span>
                </a>
                <span className="fs-5 ms-1 me-3">OR</span>
                <a href="https://intellichoiceinc.quickapp.pro/apply/applicant/new/11328" className="btn btn-light btn-large">
                  Proceed to<br/>
                  <span className="fs-5">
                    Background Check<br/>
                    (Let Intellichoice pay)
                  </span>
                </a>
              </div>
            )}
          </div>
        )}
        {this.state.backgroundCheckStatus === null && this.state.account === null && (<div className="mb-3">Loading...</div>)}
        {this.state.backgroundCheckStatus === null && this.state.account !== null && (<div>
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <button className={"nav-link" + (this.state.tab === "signups" ? " active" : "")} aria-current="page" onClick={this.onShowSignups}>Sign-ups</button>
            </li>
            <li className="nav-item">
              <button className={"nav-link" + (this.state.tab === "attendance" ? " active" : "")} aria-current="page" onClick={this.onShowAttendance}>Attendance</button>
            </li>
          </ul>
          {this.state.tab === "signups" && (
            <div className="registration-form">
              {!this.state.account?.profile && (
                <div>Fill out your <Link to="/profile">profile</Link> before signing up.</div>
              )}
              {this.state.account?.profile && (
                <div>
                  {/*<span className="small badge rounded-pill text-bg-warning">Select branch &quot;Online 10 Centeral&quot; for summer sessions</span>*/}
                  <form className="needs-validation" noValidate ref={this.formRef}>
                    <div className="row mb-1">
                      <label className="form-label">Branch</label>
                      <div className="mb-1">
                        <select className="mb-3 form-select" id="location" value={this.state.account?.locationId || ""} onChange={this.onChangeLocation} name="location">
                          <option key="" value="">--- Branch ---</option>
                          {this.state.locations.map(location => {
                            return (
                              <option key={location.id} value={location.id}>{location.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Branch</th>
                            <th>Time</th>
                            <th>Sign up</th>
                            <th>Sign-up ends</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.areThereSignups(this.state.calendars, this.state.account) && this.state.calendars.map((calendar, index) => {
                            if (this.isHomeSignup(this.state.account, calendar.locationId) || this.isSignedup(calendar.signups)) {
                              return (
                                <tr>
                                  <td>{this.getLocationName(calendar.locationId)}</td>
                                  <td>{new Intl.DateTimeFormat('en-US', {month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric'}).format(new Date(calendar.startTime))}</td>
                                  <td className="form-check">
                                    {calendar.signups.map((signup, index) => {
                                      return (
                                        <div>
                                          <input type="checkbox" className="form-check-input" id={"cal-" + calendar.id + "-" + signup.childId} name={"cal-" + calendar.id + "-" + signup.childId} checked={signup.signedup ? 1 : 0} onChange={this.onChangeSignup} />
                                          <label htmlFor={"cal-" + calendar.id + "-" + signup.childId} className={"form-check-label" + (this.isChildDeleted(signup.childId) ? " child-deleted" : "")}>{this.getChildName(signup.childId)}</label>
                                        </div>
                                      );
                                    })}
                                  </td>
                                  <td>{new Intl.DateTimeFormat('en-US', {month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric'}).format(new Date(calendar.closesForSignups))}</td>
                                </tr>
                              );
                            } else {
                              return ("");
                          }})}
                        {!this.areThereSignups(this.state.calendars, this.state.account) && (
                          <tr>
                            <td colSpan="4">No upcoming sessions to sign up for the currently selected branch. You may want to check if this branch is online only right now.  <a href="https://intellichoice.org/branches/">See branches.</a></td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </div>
                    {this.state.button !== null && (<div className="row mb-1">{this.state.button}</div>)}
                    {this.state.button === null && (<button type="button" className="btn btn-primary edit" disabled={this.state.edited ? 0 : 1} onClick={this.saveSignups}>Save</button>)}
                  </form>
                </div>
              )}
            </div>
          )}
          {this.state.tab === "attendance" && this.state.attendanceReports.map(attendanceReport => {
            return (
              <div>
                <h4>{attendanceReport.name}</h4>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Branch</th>
                        <th>Time</th>
                        <th className="text-center">Attended</th>
                        <th className="text-center">Hours</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendanceReport.attendances.map(attendance => {
                        return (
                          <tr>
                            <td>{this.getLocationName(attendance.locationId)}</td>
                            <td>{new Intl.DateTimeFormat('en-US', {dateStyle: 'medium', timeStyle: 'short'}).format(new Date(attendance.startTime))}</td>
                            <td className="text-center">{attendance.attended === null ? "Pending" : (attendance.attended ? "Yes" : "No")}</td>
                            <td className="text-center">{attendance.attended === true ? attendance.hours : ""}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <td colSpan="3" className="text-end">Total</td>
                      <td className="text-center">{new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(attendanceReport.hours)}</td>
                    </tfoot>
                  </table>
                </div>
              </div>
            );
          })}
        </div>)}
      </div>
    );
  }
}

export default withRouter(Signups);
