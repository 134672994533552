import React, { Component } from "react";
import { withRouter } from "../common/with-router";
import ProfileParent from "./profile-parent.component";
import ProfileStudent from "./profile-student.component";
import ProfileTutor from "./profile-tutor.component";

class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      role: window.sessionStorage.getItem("role")
    };
  }

  renderRole(role) {
    switch (role) {
      case "Parent":
        return <ProfileTutor />
      case "Student":
        return <ProfileTutor />
      case "Tutor":
      case "Manager":
        return <ProfileTutor />
    }
  }

  render() {
    return (
      <div>{this.renderRole(this.state.role)}</div>
    );
  }
}

export default withRouter(Profile);